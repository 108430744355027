import React from "react";
import { css } from "@emotion/core";
import Link from "./Link";
import Text from "./Text";
import theme from "../constants/theme";

export default function FeatLink({ children, border, block, invert, color, to, ...other }) {
  let defaultColor = color || theme.colors.blueDark;

  return (
    <Link
      to={to}
      css={css`
        display: ${block ? `block` : `inline-block`};
        text-align: center;
      `}
    >
      <Text
        css={[
          css`
            border-radius: ${theme.borderRadius.small};
            padding: 12px 24px;
          `,
          border
            ? css`
                color: ${invert ? `white` : defaultColor};
                border: ${`2px solid ${invert ? `white` : defaultColor}`};
              `
            : css`
                background: ${invert ? `white` : defaultColor};
                color: ${invert ? defaultColor : `white`};
              `
        ]}
        {...other}
      >
        {children}
      </Text>
    </Link>
  );
}
