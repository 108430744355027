import React, { createContext } from "react";

// Provider and Consumer are connected through their "parent" context
const { Provider, Consumer } = createContext();

// Provider will be exported wrapped in ConfigProvider component.
function ConfigProvider({ children }) {
  let [modalOpen, setModalState] = React.useState(false);

  return (
    <Provider
      value={{
        isOpen: modalOpen,
        openFunc: () => setModalState(true),
        closeFunc: () => setModalState(false),
        toggleFunc: () => setModalState(!modalOpen)
      }}
    >
      {children}
    </Provider>
  );
}

export { ConfigProvider };

// I make this default since it will probably be exported most often.
export default Consumer;
