import { css } from "@emotion/core";
import styles from "../constants/theme";

export default css`
  border-radius: ${styles.borderRadius.small};
  background: ${styles.colors.whiteAlt};
  border: none;
  padding: ${styles.margin.small};
  width: 100%;
  &::placeholder {
    color: ${styles.colors.black};
    font-style: italic;
    font-weight: bold;
  }
`;
