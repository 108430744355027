import React from "react";
import styled from "@emotion/styled";
// import { css } from "@emotion/core";
import theme from "../constants/theme";

const H1 = styled.h2`
  color: ${props => props.theme.headingColor};
  font-size: ${theme.text.h1};

  ${theme.mq[1]} {
    font-size: ${theme.text.h3};
  }
`;
const H2 = styled.h2`
  color: ${props => props.theme.headingColor};
  font-size: ${theme.text.h2};

  ${theme.mq[1]} {
    font-size: ${theme.text.h3};
  }
`;
const H3 = styled.h3`
  color: ${props => props.theme.headingColor};
  font-size: ${theme.text.h3};

  ${theme.mq[1]} {
    font-size: ${theme.text.h4};
  }
`;
const H4 = styled.h4`
  color: ${props => props.theme.headingColor};
  font-size: ${theme.text.h4};
`;
const P = styled.p`
  color: ${props => props.theme.textColor};
  font-size: ${theme.text.p};
`;
const Pfeat = styled.p`
  color: ${props => props.theme.textColor};
  font-size: ${theme.text.pFeat};
  font-weight: bold;

  ${theme.mq[1]} {
    font-size: 20px;
  }
`;
const Psmall = styled.p`
  color: ${props => props.theme.textColor};
  font-size: ${theme.text.pSmall};
`;

export default function Text({ h1, h2, h3, h4, p, pFeat, pSmall, children, ...other }) {
  if (h1) {
    return <H1 {...other}>{children}</H1>;
  } else if (h2) {
    return <H2 {...other}>{children}</H2>;
  } else if (h3) {
    return <H3 {...other}>{children}</H3>;
  } else if (h4) {
    return <H4 {...other}>{children}</H4>;
  } else if (pFeat) {
    return <Pfeat {...other}>{children}</Pfeat>;
  } else if (pSmall) {
    return <Psmall {...other}>{children}</Psmall>;
  } else {
    return <P {...other}>{children}</P>;
  }
}
