import React from 'react'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import 'typeface-noto-sans'

import Navbar from './navbar'
import Footer from './Footer'
import SubscribeModal from './SubscribeModal'
import theme from '../constants/theme'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import SubscribeModalContextConsumer, {
  ConfigProvider,
} from '../components/SubscribeModalContext'

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Global
        styles={css`
          body {
            background-color: ${theme.colors.whiteAlt};
            font-family: 'Noto Sans', sans-serif;
            font-size: ${theme.text.p};
          }
          html {
            box-sizing: border-box;
            color: ${theme.colors.blackAlt};
            /* to deal with pesky overflow issues on mobile */
            overflow-x: hidden;
            height: 100%;
            position: relative;
            min-height: 100vh;
          }

          a {
            /* for very long links */
            overflow-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
          }

          a:hover,
          a:focus {
            text-decoration: none;
            /* color: currentColor; */
          }

          .text-center {
            text-align: center !important;
          }
          .text-left {
            text-align: left !important;
          }

          p,
          h1,
          h2,
          h3,
          h4,
          h5,
          span,
          b,
          i,
          u {
            &::selection {
              color: ${theme.colors.white};
              background: ${theme.colors.blueDark};
            }
          }

          h1,
          h2,
          h3,
          h4,
          h5 {
            color: ${theme.colors.blueDark};
          }

          img {
            max-width: 100%;
          }

          .ReactModal__Html--open {
            overflow-y: hidden;
          }
          .buttonRect {
            &,
            &:hover,
            &:focus {
              background: ${theme.colors.blueDark};
              color: white;
              border-radius: ${theme.borderRadius.small};
              padding: 12px 24px;
            }
          }
          .alignleft {
            float: left;
            margin-right: 30px;
            height: auto;
          }
          .alignright {
            float: right;
            margin-left: 30px;
            height: auto;
          }
          hr {
            clear: both;
            display: block;
          }
        `}
      />
      <ConfigProvider>
        <ThemeProvider theme={theme.palette.white}>
          <SubscribeModalContextConsumer>
            {(context) => <Navbar openModal={context.toggleFunc} />}
          </SubscribeModalContextConsumer>
          <div
            css={css`
              padding-top: ${theme.nav.height};
              z-index: 1;
            `}
          >
            <main
              css={css`
                z-index: 2;
              `}
            >
              {children}
            </main>
            <Footer
              css={css`
                z-index: 1;
              `}
            />
          </div>
          <SubscribeModalContextConsumer>
            {(context) => (
              <SubscribeModal
                isOpen={context.isOpen}
                closeModal={context.closeFunc}
              />
            )}
          </SubscribeModalContextConsumer>
        </ThemeProvider>
      </ConfigProvider>
    </React.Fragment>
  )
}

export default Layout
